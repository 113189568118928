import { OfficeViewActionButtons, OfficeViewAdditionalLines, OfficeViewMiddleHeader, OfficeViewMiddleNumbers, OfficeViewSearchResults, SearchableOptions, OfficeViewCallResults, OfficeViewCallRecording } from 'dyl-components';
import React from 'react';
import './index.scss';
import { Header } from 'semantic-ui-react';

const LIMIT = 15;

const OfficeViewPhoneMiddle = ({
    onNumberClick,
    search,
    isSearchResultsVisible,
    page,
    searchFunction,
    onPersonSelect,
    onChangeSearch,
    onPageChange,
    cancelFunction,
    isSearchingContacts,
    contacts_searched_count,
    contact_lookup,
    callState,
    control,
    hangup,
    hangupDisabled,
    vmDrop,
    vmDropDisabled,
    transfer,
    transferDisabled,
    hold,
    holdDisabled,
    mute,
    muteDisabled,
    onSaveDisabled,
    callResultsDisabled,
    callTagDisabled,
    relatedToDisabled,
    currentCallPhoneNumber,
    duration,
    callRecording,
    onRemovePerson,
    isDialDisabled,
    onEmailClick,
    onTaskClick,
    onEventClick,
    onHotlistClick,
    hasIntegration,
    isInHotlist,
    logActivityForm,
    isLogActivityModalOpen,
    setIsLogActivityModalOpen,
}) => {
    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <div className='OfficeView__searchContainer'>
                <SearchableOptions
                    loading={isSearchingContacts}
                    search={search}
                    searchFunction={searchFunction}
                    cancelSearchFunction={cancelFunction}
                    searchPlaceholder={'Search by name or number'}
                    onChangeSearch={onChangeSearch}
                    isSearchResultsVisible={isSearchResultsVisible}
                    renderSearchResults={(
                        <OfficeViewSearchResults onPersonSelect={onPersonSelect} />
                    )}
                    isPaginationVisible={true}
                    paginationProps={{
                        boundaryRange: 0,
                        activePage: page,
                        ellipsisItem: null,
                        siblingRange: 2,
                        totalPages: Math.ceil(contacts_searched_count / LIMIT),
                        onPageChange
                    }}
                    floatingResults
                />
            </div>
            <div className='OfficeView__midContainer'>
                {contact_lookup ? (
                    <>
                        <OfficeViewMiddleHeader
                            person={contact_lookup}
                            onRemovePerson={onRemovePerson}
                            isDialDisabled={isDialDisabled}
                            onEmailClick={onEmailClick}
                            onTaskClick={onTaskClick}
                            onEventClick={onEventClick}
                            onHotlistClick={onHotlistClick}
                            hasIntegration={hasIntegration}
                            isInHotlist={isInHotlist}
                            logActivityForm={logActivityForm}
                            isLogActivityModalOpen={isLogActivityModalOpen}
                            setIsLogActivityModalOpen={setIsLogActivityModalOpen}
                            callState={callState}
                        />
                        {callState === "idle" && <OfficeViewMiddleNumbers person={contact_lookup} onNumberClick={onNumberClick} currentCallPhoneNumber={currentCallPhoneNumber}/>}
                    </>
                ) : callState === "idle" && (
                    <div style={{display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center'}}>
                        <Header as={"h2"}>No Active Call</Header>
                    </div>
                )}
                {callState !== "idle" &&
                    <div className='OfficeView__midContainer--space'></div>
                }
                {(callState === "hangup") && 
                    <OfficeViewCallRecording 
                        currentCallPhoneNumber={currentCallPhoneNumber} 
                        duration={duration}
                        callRecording={callRecording}
                    />
                }
                {(callState === "ringing" || callState === "active") && 
                    <OfficeViewActionButtons 
                        hangup={hangup}
                        hangupDisabled={hangupDisabled}
                        vmDrop={vmDrop}
                        vmDropDisabled={vmDropDisabled}
                        transfer={transfer}
                        transferDisabled={transferDisabled}
                        hold={hold}
                        holdDisabled={holdDisabled}
                        mute={mute}
                        muteDisabled={muteDisabled}
                    />
                }
                {callState !== "idle" && 
                    <OfficeViewCallResults 
                        control={control}
                        onSaveDisabled={onSaveDisabled}
                        callResultsDisabled={callResultsDisabled}
                        callTagDisabled={callTagDisabled}
                        relatedToDisabled={relatedToDisabled}
                    />
                }

            </div>
            <OfficeViewAdditionalLines />
        </div>

    )
}

export default OfficeViewPhoneMiddle;